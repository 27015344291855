<template>
  <b-overlay rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <b-card class="p-2 mt-2">
      <h2 class="text-primary">Vendor Detail</h2>
      <hr />
      <div class="row">
        <div class="col-sm-12">
          <b-row class="mb-1">
            <b-col class="font-weight-bold" sm="2">Vendor Name</b-col>
            <b-col> : {{ item.name }} </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="font-weight-bold" sm="2">Slug</b-col>
            <b-col> : {{ item.slug }} </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="font-weight-bold" sm="2">Organization Name</b-col>
            <b-col> : {{ item.cas_organization?.name }} </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="font-weight-bold" sm="2">Description</b-col>
            <b-col sm="10">
              :
              {{
              item.description == null || item.description == ''
              ? '-'
              : item.description
              }}
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="this.item?.sync_vendor?.vendor_premium_id_ax !== '-1'">
            <b-col class="font-weight-bold" sm="2">New Premium Vendor Item ID</b-col>
            <b-col sm="10">
              : <b-button class="px-1 py-0" sm="2" v-if="this.item?.sync_vendor?.vendor_premium_id_ax === '0'"
                @click="requestPremiumId()">

                <span style="font-size: 12px; font-weight: 500;">{{ requestBtnLoading ? 'Loading ...': 'Request Id'
                  }}</span>
              </b-button>

              <span v-else-if="this.item?.sync_vendor?.vendor_premium_id_ax === '1'" style="color: #ffc107">In
                Prosess</span>
              <span v-else> {{this.item?.sync_vendor?.vendor_premium_id_ax}}</span>
            </b-col>

          </b-row>
          <b-row class="mb-1">
            <b-col class="font-weight-bold" sm="2">Vendor Type</b-col>
            <b-col sm="10" v-if="item.is_external">
              :
              <span>External</span>
            </b-col>
            <b-col sm="2" v-else>
              :
              <span>Internal</span>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="font-weight-bold" sm="2">Meta</b-col>
            <b-col>
              : {{ item.meta == null || item.meta == '' ? '-' : item.meta }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="font-weight-bold" sm="2">Is Active</b-col>
            <b-col sm="10" v-if="item.is_active">
              :
              <span class="badge badge-primary"><i class="fa fa-check"></i> active</span>
            </b-col>
            <b-col sm="2" v-else>
              :
              <span class="badge badge-danger">
                <i class="fa fa-close"></i> inactive</span>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="font-weight-bold" sm="2">Status</b-col>
            <b-col>
              :
              <VendorStatus :vendor_status="parseInt(item.vendor_status)" />
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="font-weight-bold" sm="2">Display Content</b-col>
            <b-col>
              : {{ item.display_content == null || item.display_content == '' ? '-' : item.display_content }}
            </b-col>
          </b-row>
          <b-row class="mb-1 mt-4" v-if="!previewImage">
            <b-col class="font-weight-bold" sm="8">
              <img v-if="item.icon_image_normal !== null" class="form-control-file"
                style="width: 20%; border:1px solid #ccc;" :src="imageSrc" @error="handleImageError($event)"
                alt="Vendor Image" />
              <img v-else class="form-control-file" style="width: 20%; border:1px solid #ccc;" :src="imagePath"
                alt="Vendor Image" />
            </b-col>
          </b-row>
          <div v-if="previewImage">
            <div v-if="!item.icon_image_normal" class="form-group mt-3" style="width: 20%; overflow: hidden;;">
              <img class="form-control-file" style="width: 100%" :src="previewImage" alt="Vendor Image" />
            </div>
          </div>
          <div class="parent-wapper-image">
            <div class="upload-btn-wrapper" v-if="!item.icon_image_normal">
            <button class="btn btn-warning mt-2" style="width: 100%;">
              <i  class="fa fa-upload"></i>
              
              {{this.previewImage !== null ? 'Change Image Vendor' : 'Upload Image Vendor'}}
            </button>
          
            <input  type="file" name="file" ref="file" class="form-control-file" id="file"
              @change="onChangeData($event)" />
          </div>
          <button v-if="previewImage !== null" @click="updateImageVendor()" class="btn btn-warning mt-3" style="width: 13.2%;">
              Save
            </button>
        

        </div>
  
          </div>
                </div>
      <hr />
      <h2 class="text-primary">Aggrement</h2>
      <hr />
      <div class="row">
        <div class="col-sm-12">
          <b-row class="mb-1">
            <b-col class="font-weight-bold" sm="2">Vendor ID AX</b-col>
            <b-col>
              :
              {{ item.sync_vendor ? item.sync_vendor.vendor_id_ax : '-' }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="font-weight-bold" sm="2">Default Fixed Amount</b-col>
            <b-col>
              : {{ itemRevenue ? itemRevenue.default_fixed_amount : '-' }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="font-weight-bold" sm="2">Default Percentage Amount</b-col>
            <b-col>
              :
              <span class="font-weight-bold">{{
                itemRevenue
                ? `${toPercent(itemRevenue.default_percentage_amount)} %`
                : '-'
                }}</span>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="font-weight-bold" sm="2">Formula Name</b-col>
            <b-col>
              : {{ itemRevenue ? itemRevenue.formula_name : '-' }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="font-weight-bold" sm="2">Formula ID</b-col>
            <b-col> : {{ itemRevenue ? itemRevenue.formula_id : '-' }} </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="font-weight-bold" sm="2">Payment Currency</b-col>
            <b-col>
              :
              <span class="font-weight-bold">{{
                itemVendor ? itemVendor?.payment_currency : '-'
                }}</span>
            </b-col>
          </b-row>
        </div>
      </div>
      <hr />
      <h2 class="text-primary">Aggregation</h2>
      <hr />
      <div class="row">
        <div class="col-sm-12 rounded mb-4">
          <div class="row">
            <b-col sm="6" v-if="content.length > 0">
              <b-card>
                <ChartContent :title="'Content'" :series="seriesContent" :options="optionsContent" />
              </b-card>
            </b-col>
            <b-col sm="6" v-if="content">
              <b-card>
                <b-table sticky-header="500px" responsive="sm" show-empty striped :busy="isLoading" :fields="fields"
                  hover :items="content">
                  <template #cell(No)="row"> {{ row.index + 1 }}. </template>
                  <template #cell(count)="row">
                    {{ formatPriceBasic(row.item.count) }}
                  </template>
                </b-table>
              </b-card>
            </b-col>
          </div>
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import constant from '../../store/constant';
import VendorStatus from '../../components/vendor/vendorStatus.vue';
import defaultImage from '../../../src/assets/img/default.jpg'; 
import ChartContent from '../../components/dashboard/Chartcontent';
import moment from 'moment';
export default {
  name: 'DetailVendor',
  components: {
    VendorStatus,
    ChartContent,
  },
  data() {
    return {
      imageSrc: '',
      requestBtnLoading:false,
      typeChart: 'line',
      series: [],
      seriesContent: [],
      options: {},
      file: null,
      previewImage:null,
      saveImge:'Save Image',
      optionsContent: {},
      imagePath: defaultImage,
      fields: [
        { key: 'No', label: 'No' },
        { key: 'item_type', label: 'Content Type' },
        { key: 'item_status', label: 'Status' },
        { key: 'count', label: 'Qty' },
      ],
    };
  },
  computed: {
    ...mapState({
      item: (state) => state.vendors.item,
      itemRevenue: (state) => state.vendors.itemRevenue,
      isError: (state) => state.vendors.isError,
      successMessage: (state) => state.vendors.successMessage,
      errorMessage: (state) => state.vendors.errorMessage,
      content: (state) => state.dashboard.Content,
      isLoadingContent: (state) => state.dashboard.isLoadingContent,
      isLoading: (state) => state.dashboard.isLoading,
      itemVendor: (state) => state.vendorScoopint.item,
    }),
  },
  mounted() {
    this.name = localStorage.getItem('session_name');
    this.actionSetLabelChart();
    this.actionFetchContent();
  },
  watch: {
    item: function() {
      if (!Object.keys(this.item).length) return;
      this.getVendor();
    },
    itemRevenue: function() {
      if (!Object.keys(this.itemRevenue).length) return;
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something Went Wrong. Please Try Again', 'error');
    },
    successMessage: function() {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
      this.$router.push('/vendor');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
    content() {
      if (this.content.length > 0) {
        this.actionLoadChartContent();
      }
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Detail Vendor | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  created: function() {
    const vendorID = this.$route.params.id;
    this.fetchVendorsById({ id: vendorID });
    this.getVendorRevenue({ id: vendorID });
    this.findVendorScoopintByID({ id: vendorID });
  },
  methods: {
    ...mapActions('vendors', ['fetchVendorsById', 'getVendorRevenue','postVendorsPremiumById','imageVendorDetail']),
    ...mapActions('vendorScoopint', ['findVendorScoopintByID']),
    ...mapActions('dashboard', ['Content']),

    getVendor() {
      this.imageSrc =
        constant.IMG_URL_STATIC_VENDOR.URL + this.item.icon_image_normal;
    },
    async requestPremiumId() {
      this.requestBtnLoading = true
      const id = this.$route.params.id;
      const payload = {id};
      await this.postVendorsPremiumById(payload).then((response) => {
        this.requestBtnLoading = false  
        if (response.status == 200) {
          this.alertMsg('Request Premium Success','success');
          this.$router.push(`/vendor/detail/${id}`);
        }
      })
    },
    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },

    // ================== AGGREGATE =================== //
    actionFetchContent() {
      const vendor_id = this.$route.params.id;
      const payload = {
        vendor_id,
      };
      this.Content(payload); //ccall content api
    },
    handleImageError(event) {
      event.target.src = this.imagePath;
    },
    async updateImageVendor() {
        if (this.previewImage !== null) {
          const vendorID = this.$route.params.id;
          const formData = new FormData();
          formData.append('file', this.file);
            const payload = {
              id: vendorID,
              formData: formData,
            }; 
         await this.imageVendorDetail(payload);
      }
    },
    onChangeData(e) {
      this.file = e.target.files[0];
      if (this.file) {
        const allowedTypes = ['image/png', 'image/jpeg'];
        if (!allowedTypes.includes(this.file.type)) {
          this.alertMsg('File type must be (.png, .jpg, .jpeg)','error')
          this.$refs.file.value = ''; 
          return;
        }

        const maxSize = 10 * 1024 * 1024; 
        if (this.file.size > maxSize) {
          this.alertMsg('Max file only 10MB.','error')
          this.$refs.file.value = ''; 
          return;
        }

        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(this.file);
      }
    },
    actionLoadChartContent() {
      if (this.content) {
        this.seriesContent = this.content.map((e) => parseInt(e.count, 10));
        this.optionsContent = {
          title: {
            text: 'Content ',
            align: 'left',
          },
          colors: [
            '#0060AF',
            '#ffec2e',
            '#ff06dc',
            '#0060af',
            '#f4975f',
            '#82a8f4',
          ],
          labels: this.content.map((e) => `${e.item_type} (${e.item_status})`),
          dataLabels: {
            enabled: true,
            formatter: function(val) {
              return parseFloat(val).toFixed(2) + '%';
            },
          },
          plotOptions: {
            pie: {
              expandOnClick: true,
              donut: {
                labels: {
                  show: true,
                  value: {
                    formatter: function(value) {
                      let val = (value / 1).toFixed(0).replace('.', ',');
                      return val
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    },
                  },
                },
              },
            },
          },
        };
      }
    },
    actionSetLabelChart() {
      this.label_chart = [];
      let startMonth = moment()
        .subtract(this.showCountMonth, 'month')
        .format('YYYY-MM-DD');
      for (let index = 0; index < this.showCountMonth; index++) {
        let inMonth = moment(startMonth)
          .add(index, 'month')
          .format('YYYY-MM-DD');
        this.label_chart.push(
          moment(inMonth)
            .endOf('month')
            .format('YYYY-MM-DD'),
        );
      }
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    toPercent(num) {
      return (parseFloat(num) * 100).toFixed(1);
    },
  },
};
</script>
<style>

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 13.2%;
}
.parent-wapper-image {
  display: flex;
  flex-direction: column;
}
.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
</style>